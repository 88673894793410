export const RUSSIAN_STATE = {
    lang: 'ru',
    messages: {

        //PLAYER VIEW MESSAGES START
        'app.gameselection.title': 'Игра Pulmikas',
        'app.gameselection.gameid': 'ID игры',
        'app.gameselection.instruction': 'Введи ID, показанный на общем экране',

        'app.login.title': 'Войти',
        'app.login.logout': 'Выйти',
        'app.login.wrongcredentials': 'Неверный ID  или пароль',
        'app.login.password': 'пароль',
        'app.login.username': 'ID',

        'player.groupselection.title': 'Выбери команду',
        'player.drawavatar.title': 'Нарисуй',
        'player.drawavatar.instruction': 'Нарисуй себе аватарку',
        'player.namegroup.title': 'Название команды',
        'player.namegroup.instruction': 'Придумайте вместе название команды',

        //Fact question
        'player.factquestion.answer.title': 'Введи свой ответ',
        'facilitator.factquestion.answer': 'Подожди, пока ответят все игроки.',
        'facilitator.factquestion.result': 'Игроки проверяют правильный ответ.',

        //Open question
        'player.openquestion.answerscreen.placeholder': 'Максимальная длина: 100 символов',
        'screen.openquestion.answerscreen.choosebest' : 'Выбери лучший ответ из следующих вариантов',
        'player.openquestion.answerscreen.voteinstruction': 'Выбери лучший ответ',
        'facilitator.openquestion.answerscreen.answer': 'Подожди, пока игроки обсудят подсказки и введут свой ответ.',

        //Ending
        'player.ratescreen.title': 'Спасибо за игру!',
        'player.ratescreen.instruction': 'Какую оценку ты поставишь этой игре?',
        //PLAYER VIEW MESSAGES END


        //FACILITATOR VIEW MESSAGES START
        //Exit
        'facilitator.gameexit.confirmation': 'Ты точно хочешь выйти из игры?',
        'facilitator.logout.confirmation': 'Ты точно хочешь выйти из системы?',
        //Game select
        'facilitator.gameselect.title': 'Выбери игру',

        //Grouping
        'facilitator.groupsize.title': 'Сколько команд?',
        'facilitator.groupsize.instruction': 'Рекомендуемое число игроков: 3–4 человека. Максимальное количество команд: 6',
        'facilitator.groupmoderation.title': 'Модерация',
        'facilitator.groupmoderation.instruction': 'Команды готовы',
        'facilitator.groupmoderation.namereset': 'Поменять название', 
        'facilitator.groupmoderation.info': 'Игроки присоединяются к игре. Начни игру, когда к ней присоединятся все',

        //Facilitator wait screen
        'facilitator.waitscreen.read': 'Игроки читают. Ты тоже можешь прочитать этот текст вслух.',
        'facilitator.waitscreen.discuss': 'Игроки обсуждают вопрос.',
		'facilitator.waitscreen.multiquestion.discuss': 'Игроки ведут обсуждение. Когда ты перейдешь на следующий этап, им будут показаны варианты ответов и подсказки.',
		'facilitator.waitscreen.openquestion.discuss': 'Игроки ведут обсуждение. Когда ты перейдешь на следующий этап, им будет показано поле для ответа и подсказки.',
        'facilitator.waitscreen.debatewheel': 'Игрок, который объясняет ответ, выбирается случайным образом.',
        'facilitator.waitscreen.voteresults': 'Игроки рассматривают результаты голосования.',
        'facilitator.waitscreen.debateresults': 'Игроки рассматривают результаты.',

        //Big screen setup
        'facilitator.setupbigscreen.title': 'Общий экран',
        'facilitator.setupbigscreen.subtitle': 'Открой игровое окно, перейдя по адресу:',
        'facilitator.setupbigscreen.description': 'pulmikas.net/ru/screen',
        'facilitator.setupbigscreen.screencode': 'ID игры',
        'facilitator.setupbigscreen.instructiontitle': 'Помощь:',
        'facilitator.setupbigscreen.instructioncontent-1': 'Общий экран с игрой должен быть виден всем игрокам.',
        'facilitator.setupbigscreen.instructioncontent-2': 'Если вы можете совместно использовать экран данного устройства, откройте еще одно окно в браузере и перейдите по адресу pulmikas.net/ru/screen.',
        'facilitator.setupbigscreen.instructioncontent-3': 'Если вы пользуетесь другим устройством для совместного использования экрана, перейдите по адресу pulmikas.fi/screen, используя это устройство, и введите ID в открывшееся окно.',
        'facilitator.setupbigscreen.url': "/ru/screen",
        //Fact question
        'facilitator.factquestion.answermoderation.answers': 'ответы',

        //Multi question
        'facilitator.multiquestion.answerreset': 'Удалить ответ',
        'facilitator.multiquestion.answer': 'Ответ:',
        'facilitator.multiquestion.answerscreen.waitingforanswer': 'Ожидается ответ',
        'facilitator.multiquestion.answerscreen.ready': 'Готово',
        'facilitator.multiquestion.debatemoderation.title': 'Объяснение',
        'facilitator.multiquestion.debatemoderation.speaking': 'Сейчас говорит эта команда:',
        'facilitator.multiquestion.debatemoderation.accepted': 'Принято',
        'facilitator.multiquestion.debatemoderation.declined': 'Отклонено',
        'facilitator.multiquestion.debatemoderation.instruction': 'Выслушать объяснение команды',
        'facilitator.multiquestion.answer.answer': 'Подожди, пока игроки обсудят подсказки и выберут ответ.',
        'facilitator.multiquestion.answer.result': 'Игроки рассматривают результаты.',
        //FACILITATOR VIEW MESSAGES END

        //SCREEN VIEW MESSAGES START

        //Start
        'screen.welcomescreen.title': 'Добро пожаловать!',
        'screen.welcomescreen.instruction': 'Перейди на pulmikas.net',

        //Fact question
        'screen.factquestion.answerScreen.title': 'Правильный ответ',

        //Multiple choice question
        'screen.multiquestion.debatescreen.instruction': 'Объясните, почему вы решили, что выбранный вами вариант самый лучший',

        //Open question
        'screen.openquestion.debatescreen.instruction': 'Объясните ответ вашей команды',
        'facilitator.openquestion.vote': 'Игроки голосуют за лучший ответ.',


        //Debate views
        'screen.debatewheel.title': 'Кто будет объяснять?',
        //SCREEN VIEW MESSAGES END

        //Universal
        'thanksforplaying': 'Спасибо за игру',
        'startbutton': 'Выбери игру',
        'startgamebutton': 'Начать игру',
        'startnewgame': 'Начать новую игру',
        'logout': 'Выйти',
        'readybutton': 'Готово',
        'nextbutton': 'Далее',
        'group': 'Команда',
        'wait': 'Подождите',
        'yes': 'Да',
        'no': 'Нет',
        'hint': 'Подсказка',
        'discuss': 'Обсудите',
        'listen': 'Слушайте',
        'debate': 'Объясните',
        'forward': 'Cледующее задание',
        'send': 'Отправь',
        'press1second': 'Удерживайте в течение двух секунд',
        'waitMoment': 'Немного подождите',
        'errorMessage': 'Что-то пошло не так, попробуйте войти снова'
    }
  };