import React from "react"

import speechBubbles from "static/svg/speech-bubbles.svg"
import leaveGame from "static/svg/leave-game.svg"

export default class InstructionScreen extends React.Component {
  render() {
    let { groups } = this.props.information.data
    let { data } = this.props.information
    return (
      <div className="app-container">
        <div className="container-fluid player-container">
          <span className="exit-button" onClick={() => this.props.exitGame()}>
            <img style={{ width: "100%", height: "100%" }} src={leaveGame} />
          </span>
          <div className="row">
            <div className="col-md">
              <p className="app-paragraph" style={{ textAlign: "right" }}>
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="title-container col-md"
              style={{ paddingTop: 0, marginBottom: 0 }}
            >
            </div>
          </div>
          <div className="row">
            <img
              className="hourglass"
              style={{ marginTop: "25%", marginBottom: "25%" }}
              src={speechBubbles}
            />
          </div>
          <div className="row">
            <div className="d-flex justify-content-center col-md">
            </div>
          </div>
        </div>
      </div>
    )
  }
}
