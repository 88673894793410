export const SWEDISH_STATE = {
    lang: 'se',
    messages: {

        //PLAYER VIEW MESSAGES START
        'app.gameselection.title': 'Pulmikas-spelet',
        'app.gameselection.gameid': 'Spelkod',
        'app.gameselection.instruction': 'Skriv spelkoden som syns på den gemensamma skärmen',

        'app.login.title': "Logga in",
        'app.login.logout': "Logga ut",
        'app.login.wrongcredentials': "Fel användarnamn eller lösenord",
        'app.login.password': 'lösenord',
        'app.login.username': 'användarnamn',

        'player.groupselection.title': 'Välj lag',
        'player.drawavatar.title': 'Rita',
        'player.drawavatar.instruction': 'Rita en karaktärsbild åt dig själv',
        'player.namegroup.title': 'Lagets namn',
        'player.namegroup.instruction': 'Hitta tillsammans på ett namn åt ert lag',

        //Fact question
        'player.factquestion.answer.title': 'Ge ditt svar',
        'facilitator.factquestion.answer': 'Vänta tills alla spelare svarat.',
        'facilitator.factquestion.result': 'Spelarna granskar det rätta svaret.',

        //Open question
        'player.openquestion.answerscreen.placeholder': 'Maximal längd: 100 tecken',
        'screen.openquestion.answerscreen.choosebest' : 'Välj det svarsalternativ ni tycker att passar bäst',
        'player.openquestion.answerscreen.voteinstruction': 'Välj det bästa svaret',
        'facilitator.openquestion.answerscreen.answer': 'Vänta medan spelarna diskuterar tipsen och skriver sitt svar',
        //Ending
        'player.ratescreen.title': 'Tack för spelet!',
        'player.ratescreen.instruction': 'Vilket vitsord skulle du ge spelet?',
        //PLAYER VIEW MESSAGES END


        //FACILITATOR VIEW MESSAGES START
        //Exit
        'facilitator.gameexit.confirmation': 'Är du säker på att du vill avsluta spelet?',
        'facilitator.logout.confirmation': 'Vill du säkert logga ut?',
        //Game select
        'facilitator.gameselect.title': 'Välj spel',

        //Grouping
        'facilitator.groupsize.title': 'Hur många lag?',
        'facilitator.groupsize.instruction': 'Vi rekommenderar att det finns 3-4 spelare i varje lag. Högst 6 lag kan spela samtidigt',
        'facilitator.groupmoderation.title': 'Moderering',
        'facilitator.groupmoderation.instruction': 'Antal lag klara',
        'facilitator.groupmoderation.namereset': 'Radera namn',
        'facilitator.groupmoderation.info': 'Spelarna ansluter sig till spelet. Påbörja spelet när alla är med.',

        //Facilitator wait screen
        'facilitator.waitscreen.read': 'Spelarna läser. Du kan också läsa texten högt.',
        'facilitator.waitscreen.discuss': 'Spelarna diskuterar frågan.',
		'facilitator.waitscreen.multiquestion.discuss': 'Spelarna diskuterar. När du rör dig framåt, får de svarsalternativ och ledtrådar.',
		'facilitator.waitscreen.openquestion.discuss': 'Spelarna diskuterar. När du rör dig framåt, får de ett svarsfält och ledtrådar.',
        'facilitator.waitscreen.debatewheel': 'Turen att motivera tilldelas genom lottning.',
        'facilitator.waitscreen.voteresults': 'Spelarna tar del av resultatet av omröstningen.',
        'facilitator.waitscreen.debateresults': 'Spelarna tar del av resultaten.',

        //Big screen setup
        'facilitator.setupbigscreen.title': 'Gemensam skärmbild',
        'facilitator.setupbigscreen.subtitle': 'Öppna spelvyn genom att gå till adressen:',
        'facilitator.setupbigscreen.description': 'pulmikas.net/se/screen',
        'facilitator.setupbigscreen.screencode': 'Spelkod',
        'facilitator.setupbigscreen.instructiontitle': 'Anvisningar:',
        'facilitator.setupbigscreen.instructioncontent-1': 'Spelets gemensamma vy bör synas för alla spelare.',
        'facilitator.setupbigscreen.instructioncontent-2': 'Om den här enhetens vy kan visas för alla spelare, öppna adressen pulmikas.net/se/screen i ett annat fönster i webbläsaren.',
        'facilitator.setupbigscreen.instructioncontent-3': 'Om du använder en annan enhet för att visa den gemensamma vyn, gå med den till adressen: pulmikas.net/se/screen och ge koden i det fönster som öppnas.',
        'facilitator.setupbigscreen.url': "/se/screen",

        //Fact question
        'facilitator.factquestion.answermoderation.answers': 'svaret/svar',

        //Multi question
        'facilitator.multiquestion.answerreset': 'Radera svaret',
        'facilitator.multiquestion.answer': 'Svar: ',
        'facilitator.multiquestion.answerscreen.waitingforanswer': 'Väntar på svaret',
        'facilitator.multiquestion.answerscreen.ready': 'Klar',
        'facilitator.multiquestion.debatemoderation.title': 'Motivering',
        'facilitator.multiquestion.debatemoderation.speaking': 'Lag som har ordet:',
        'facilitator.multiquestion.debatemoderation.accepted': 'Godkänt',
        'facilitator.multiquestion.debatemoderation.declined': 'Underkänt',
        'facilitator.multiquestion.debatemoderation.instruction': 'Lyssna på lagets motivering',
        'facilitator.multiquestion.answer.answer': 'Vänta medan spelarna diskuterar tipsen och väljer ett svar.',
        'facilitator.multiquestion.answer.result': 'Spelarna granskar resultaten.',
        //FACILITATOR VIEW MESSAGES END

        //SCREEN VIEW MESSAGES START

        //Start
        'screen.welcomescreen.title': 'Välkommen',
        'screen.welcomescreen.instruction': ' Gå till adressen pulmikas.net',

        //Fact question
        'screen.factquestion.answerScreen.title': 'Rätt svar',

        //Multiple choice question
        'screen.multiquestion.debatescreen.instruction': 'Berätta varför svaret ni valde var det bästa av alternativen',

        //Open question
        'screen.openquestion.debatescreen.instruction': 'Motivera ert lags svar',
        'facilitator.openquestion.vote': 'Spelarna röstar om det bästa svaret.',


        //Debate views
        'screen.debatewheel.title': 'Vem får motivera?',
        //SCREEN VIEW MESSAGES END

        //Universal
        'thanksforplaying': "Tack för att ni spelade!",
        'startbutton': 'Välj spel',
        'startgamebutton': 'Starta spel',
        'startnewgame': 'Börja nytt spel',
        'logout': 'Logga ut',
        'readybutton': 'Klar',
        'nextbutton': 'Nästa',
        'group': 'Lag',
        'wait': 'Vänta',
        'yes': 'Ja',
        'no': 'Nej',
        'hint': 'Tips',
        'discuss': 'Diskutera',
        'listen': 'Lyssna',
        'debate': 'Motivera',
        'forward': 'Framåt',
        'send': 'Sänd',
        'press1second': 'Tryck i en halv sekund',
        'waitMoment': 'Vänta ett ögonblick',
        'errorMessage': 'Något gick fel, försök logga in på nytt'
    }
  };