import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import ScreenStepper from '../../../../utils/ScreenStepper.jsx'
import Pulmikas from '../../../../utils/Pulmikaslogo.jsx';

export default class DebateScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            interval: null,
            timeout: null
        };
        this.checkScores = this.checkScores.bind(this);
        this.animateScore = this.animateScore.bind(this);
    }

    componentDidUpdate() {
        if(this.state.timeout == null) {
            this.state.timeout = setTimeout(() => this.checkScores(), 2000);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
        clearInterval(this.state.interval);
    }

    checkScores() {
        let {groups} = this.props.information.data;
        let debateGroup = this.props.information.data.debate_group;
            if(groups[debateGroup].score > groups[debateGroup].previous_score){
                this.state.interval = setInterval(() => this.animateScore(), 10);
            }
    }

    animateScore() {
        let element = document.getElementById(`score-big`);
        let element_add = document.getElementById(`score-add-big`);
        let {groups} = this.props.information.data;
        let debateGroup = this.props.information.data.debate_group;
        
        if(element.innerHTML == groups[debateGroup].score){
            clearInterval(this.state.interval)
            element_add.classList.add('fade-out');
        } else {
            let value = parseInt(element.innerHTML);
            value++;
            element.innerHTML = value;
            //element_add.innerHTML = '+' + String(groups[debateGroup].score - value);
        }
    }
    render(){
        let {data} = this.props.information;
        const {groups} = this.props.information.data;
        let avatars;
        let scoreRow;
        let group_string = "";
        let debateGroup = Object.assign({}, groups[data.debate_group]);
        if(debateGroup.name === null || debateGroup.name == ""){
            group_string = <FormattedMessage id = "group"/>;
            debateGroup.name = data.debate_group;
        }
        if(debateGroup.name.length > 13){
            debateGroup.name = debateGroup.name.slice(0,11)+'...'
        }
        let score_add = debateGroup.score - debateGroup.previous_score;

        scoreRow = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_text = "";
            let name = group.name;
            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/>
                name = index
            }
            if(name.length > 13){
                name = name.slice(0,11) + '...';
            }
            return(
                <p className="screen-group-small"> {group_text} {name} <span className="score"> {group.score} </span> </p>
            )
        });
        if(debateGroup.players){
            avatars = Object.keys(debateGroup.players).map((player_id) => {
                let player = debateGroup.players[player_id];

                if(player.avatar){
                    return(
                        <img src={player.avatar} style={{height:'15rem', width:'15rem',}}/>
                    )
                }
            })
        }

        let answer = "";
        if(data.show_answer_on_debate === true) {
            answer = (
                  <div className="row" style={{marginTop:'5rem'}} >
                        <div className="justify-content-center col-md screen-group-answer">
                            <p className="screen-option-index"> {data.groups[data.debate_group].answer.index}</p>
                            <p className="screen-option-text"> {data.groups[data.debate_group].answer.text}</p>
                        </div>
                    </div>
            );
        }
        return (
            <div className="screen-container">
                <div className="container-fluid d-flex flex-column">
                    <p className="screen-gameid-small"> <FormattedMessage id = "app.gameselection.gameid"/>: {this.props.information.data.player_code} </p>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            {scoreRow}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center" style={{flex:'1'}}>
                    <div className="row" >
                        <div className="d-flex justify-content-center col-md">
                            <div className="screen-big-team" style={{verticalAlign:'text-bottom', marginTop:0}}>
                                <div style={{maxWidth:'45rem', margin:'0 auto', maxHeight:'80%'}}>
                                    {avatars}
                                </div>
                                <p className="screen-group-big"> {group_string}  {debateGroup.name}</p>
                                <div style={{height:'14rem', textAlign:'center'}}>
                                    <p id="score-big" className="score-big">{debateGroup.previous_score !== null ? debateGroup.previous_score : debateGroup.score}</p>
                                    <p id="score-add-big" className="score-add-big">{score_add ? '+' + score_add : null } </p> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'10rem'}} >
                        <div className="d-flex justify-content-center col-md">
                            <p className="screen-instruction" style={{width:'70%', fontSize:'6rem'}}>{data.debate_content}</p>
                        </div>
                    </div>
                    {answer}
                    </div>
                    <Pulmikas screen={true}/>
                    <ScreenStepper
                        data={data}
                    />

                </div>
            </div>
        );
    }
}