export const BASQUE_STATE = {
    lang: 'eu',
    messages: {

        //PLAYER VIEW MESSAGES START
        'app.gameselection.title': 'Pulmikas jokoa',
        'app.gameselection.gameid': 'Jolasaren identifikazio-kodea',
        'app.gameselection.instruction': 'Idatzi pantaila partekatuan agertzen den identifikazio-kodea',

        'app.login.title': 'Hasi saioa',
        'app.login.logout': 'Itxi saioa',
        'app.login.wrongcredentials':  'Erabiltzaile edo pasahitz okerra!',
        'app.login.password': 'pasahitza',
        'app.login.username': 'Identifikazio-kodea',

        'player.groupselection.title': 'Aukeratu talde bat',
        'player.drawavatar.title': 'Marraztu',
        'player.drawavatar.instruction': 'Marraztu zure abatarra',
        'player.namegroup.title': 'Taldearen izena',
        'player.namegroup.instruction': 'Eman izena taldeari elkarrekin',

        //Fact question
        'player.factquestion.answer.title': 'Idatzi erantzuna',
        'facilitator.factquestion.answer': 'Itxaron jokalari guztiek erantzun arte.',
        'facilitator.factquestion.result': 'Jokalariak erantzun egokia egiaztatzen ari dira.',

        //Open question
        'player.openquestion.answerscreen.placeholder': 'Luzera, gehienez: 100 karaktere',
        'screen.openquestion.answerscreen.choosebest' : 'Hautatu erantzun egokiena aukera hauen artean',
        'player.openquestion.answerscreen.voteinstruction': 'Hautatu erantzun egokiena',
        'facilitator.openquestion.answerscreen.answer': 'Itxaron jokalariak pistez eztabaidatzen eta erantzunak idazten ari diren bitartean.',

        //Ending
        'player.ratescreen.title': 'Eskerrik asko jokatzeagatik',
        'player.ratescreen.instruction': 'Nola baloratuko zenuke joko hau?',
        //PLAYER VIEW MESSAGES END


        //FACILITATOR VIEW MESSAGES START
        //Exit
        'facilitator.gameexit.confirmation': 'Ziur zaude irten egin nahi duzula?',
        'facilitator.logout.confirmation': 'Ziur al zaude saio itxi nahi duzula?',
        //Game select
        'facilitator.gameselect.title': 'Hautatu joko bat',

        //Grouping
        'facilitator.groupsize.title': 'Zenbat talde?',
        'facilitator.groupsize.instruction': 'Taldeen tamaina gomendatua 3-4 jokalarikoa da. Gehienez ere 6 talde egin',
        'facilitator.groupmoderation.title': 'Moderazioa',
        'facilitator.groupmoderation.instruction': 'Taldeak prest',
        'facilitator.groupmoderation.namereset': 'Berrezarri izena',
        'facilitator.groupmoderation.info': 'Jokalariak jokoan sartzen ari dira. Hasi jokoa guztiak barruan daudenean.',

        //Facilitator wait screen
        'facilitator.waitscreen.read': 'Jokalariak prest daude. Sarrerako testua ozen ere irakur dezakezu.',
        'facilitator.waitscreen.discuss': 'Jokalariak galdera batez eztabaidatzen ari dira.',
		'facilitator.waitscreen.multiquestion.discuss': 'Jokalariak eztabaidatzen ari dira. Aurrera egiten duzunean, erantzuteko aukerak eta pistak jasoko dituzte.',
		'facilitator.waitscreen.openquestion.discuss': 'Jokalariak eztabaidatzen ari dira. Aurrera egiten duzunean, erantzuteko aukerak eta pistak jasoko dituzte.',
        'facilitator.waitscreen.debatewheel': 'Erantzuna azaltzeko jokalaria ausaz aukeratuko da.',
        'facilitator.waitscreen.voteresults': 'Jokalariak bozketaren emaitza ikusten ari dira.',
        'facilitator.waitscreen.debateresults': 'Jokalariak emaitza ikusten ari dira.',

        //Big screen setup
        'facilitator.setupbigscreen.title': 'Pantaila partekatua',
        'facilitator.setupbigscreen.subtitle': 'Ireki jokoaren bista, hemen:',
        'facilitator.setupbigscreen.description': 'pulmikas.net/eu/screen',
        'facilitator.setupbigscreen.screencode': 'Jolasaren identifikazio-kodea',
        'facilitator.setupbigscreen.instructiontitle': 'Laguntza',
        'facilitator.setupbigscreen.instructioncontent-1': 'Jolasaren pantaila partekatua ikusgai egon behar da jokalari guztientzat.',
        'facilitator.setupbigscreen.instructioncontent-2': 'Gailu honen pantaila parteka badezakezu, ireki beste leiho bat nabigatzailean eta joan helbide honetara: pulmikas.fi/screen.',
        'facilitator.setupbigscreen.instructioncontent-3': 'Beste gailu bat erabiltzen baduzu pantaila partekatzeko, joan pulmikas.fi/screen helbidera gailu hori erabiliz eta sartu identifikazio-kodea ireki den leihoan.',
        'facilitator.setupbigscreen.url': '/screen',

        //Fact question
        'facilitator.factquestion.answermoderation.answers': 'erantzunak',

        //Multi question
        'facilitator.multiquestion.answerreset': 'Ezabatu erantzuna',
        'facilitator.multiquestion.answer': 'Erantzuna:',
        'facilitator.multiquestion.answerscreen.waitingforanswer': 'Erantzunaren zain',
        'facilitator.multiquestion.answerscreen.ready': 'Prest',
        'facilitator.multiquestion.debatemoderation.title': 'Azalpena',
        'facilitator.multiquestion.debatemoderation.speaking': 'Hitz egiten ari den taldea:',
        'facilitator.multiquestion.debatemoderation.accepted': 'Onartua',
        'facilitator.multiquestion.debatemoderation.declined': 'Baztertua',
        'facilitator.multiquestion.debatemoderation.instruction': 'Entzun taldearen azalpenari',
        'facilitator.multiquestion.answer.answer': 'Itxaron jokalariak pistez eztabaidatzen eta erantzuna hautatzen ari diren bitartean.',
        'facilitator.multiquestion.answer.result': 'Jokalariak emaitza ikusten ari dira.',
        //FACILITATOR VIEW MESSAGES END

        //SCREEN VIEW MESSAGES START

        //Start
        'screen.welcomescreen.title': 'Ongi etorri',
        'screen.welcomescreen.instruction': 'Joan pulmikas.net helbidera',

        //Fact question
        'screen.factquestion.answerScreen.title': 'Erantzun zuzena',
        
        //Multiple choice question
        'screen.multiquestion.debatescreen.instruction': 'Mesedez, azaldu zergatik uste duzun zure hautua dela onena',

        //Open question
        'screen.openquestion.debatescreen.instruction': 'Mesedez, azaldu zure taldearen erantzuna',
        'facilitator.openquestion.vote': 'Jokalariak erantzun onena bozkatzen ari dira.',

        
        //Debate views
        'screen.debatewheel.title': 'Nork azalduko du?',
        //SCREEN VIEW MESSAGES END

        //Universal
        'thanksforplaying': 'Eskerrik asko jokatzeagatik',
        'startbutton': 'Hautatu joko bat',
        'startgamebutton': 'Hasi jokoa',
        'startnewgame': 'Hasi joko berri bat',
        'logout': 'Itxi saioa',
        'readybutton': 'Prest',
        'nextbutton': 'Hurrengoa',
        'group': 'Taldea',
        'wait': 'Itxaron',
        'yes': 'Bai',
        'no': 'Ez',
        'hint': 'Pista',
        'discuss': 'Eztabaidatu',
        'listen': 'Entzun',
        'debate': 'Azaldu',
        'forward': 'Aurrera',
        'send': 'Bidali',
        'press1second': 'Sakatu botoia bi segundoz',
        'waitMoment': 'Itxaron apur batean',
        'errorMessage': 'Zerbait gaizki atera da, saiatu saioa berriz hasten'
    }
  };