export const ENGLISH_STATE = {
    lang: 'en',
    messages: {

        //PLAYER VIEW MESSAGES START
        'app.gameselection.title': 'Pulmikas Game',
        'app.gameselection.gameid': 'Game ID',
        'app.gameselection.instruction': 'Enter the ID shown on the shared screen',

        'app.login.title': "Log in",
        'app.login.logout': "Log out",
        'app.login.wrongcredentials': "Incorrect ID or password!",
        'app.login.password': 'password',
        'app.login.username': 'ID',

        'player.groupselection.title': 'Choose a team',
        'player.drawavatar.title': 'Draw',
        'player.drawavatar.instruction': 'Draw yourself an avatar',
        'player.namegroup.title': 'Team name',
        'player.namegroup.instruction': 'Give your team a name together',

        //Fact question
        'player.factquestion.answer.title': 'Enter your answer',
        'facilitator.factquestion.answer': 'Wait until all players have answered.',
        'facilitator.factquestion.result': 'Players are checking out the right answer.',

        //Open question
        'player.openquestion.answerscreen.placeholder': 'Maximum length: 100 characters',
        'screen.openquestion.answerscreen.choosebest' : 'Choose the best answer from the following options',
        'player.openquestion.answerscreen.voteinstruction': 'Choose the best answer',
        'facilitator.openquestion.answerscreen.answer': 'Wait while players are discussing the clues and entering their answer.',

        //Ending
        'player.ratescreen.title': 'Thank you for \n playing',
        'player.ratescreen.instruction': 'How would you rate this game?',
        //PLAYER VIEW MESSAGES END


        //FACILITATOR VIEW MESSAGES START
        //Exit
        'facilitator.gameexit.confirmation': 'Are you sure you want to quit?',
        'facilitator.logout.confirmation': 'Are you sure you want to log out?',
        //Game select
        'facilitator.gameselect.title': 'Choose a game',

        //Grouping
        'facilitator.groupsize.title': 'How many teams?',
        'facilitator.groupsize.instruction': 'The recommended team size is 3–4 players. The maximum number of teams is 6',
        'facilitator.groupmoderation.title': 'Moderation',
        'facilitator.groupmoderation.instruction': 'Teams ready',
        'facilitator.groupmoderation.namereset': 'Reset name',
        'facilitator.groupmoderation.info': 'Players are joining the game. Start the game when everyone is in.',

        //Facilitator wait screen
        'facilitator.waitscreen.read': 'Players are reading. You can also read the text out loud.',
        'facilitator.waitscreen.discuss': 'Players are discussing a question.',
		'facilitator.waitscreen.multiquestion.discuss': 'Players are discussing. When you move on, they will get the answer options and clues.',
		'facilitator.waitscreen.openquestion.discuss': 'Players are discussing. When you move on, they will get the answer field and clues.',
        'facilitator.waitscreen.debatewheel': 'The player to explain the answer is selected randomly.',
        'facilitator.waitscreen.voteresults': 'Players are looking at the vote results.',
        'facilitator.waitscreen.debateresults': 'Players are looking at the results',

        //Big screen setup
        'facilitator.setupbigscreen.title': 'Shared screen',
        'facilitator.setupbigscreen.subtitle': 'Open the game view by navigating to:',
        'facilitator.setupbigscreen.description': 'pulmikas.net/en/screen',
        'facilitator.setupbigscreen.screencode': 'Game ID',
        'facilitator.setupbigscreen.instructiontitle': 'Help:',
        'facilitator.setupbigscreen.instructioncontent-1': 'The shared game screen must be visible to all players.',
        'facilitator.setupbigscreen.instructioncontent-2': 'If you can share the screen of this device, open another browser window and go to the address pulmikas.net/en/screen.',
        'facilitator.setupbigscreen.instructioncontent-3': 'If you use another device for screen sharing, go to the address pulmikas.net/en/screen using that device and enter the ID in the window that opens.',
        'facilitator.setupbigscreen.url': "/en/screen",
        //Fact question
        'facilitator.factquestion.answermoderation.answers': 'answers',

        //Multi question
        'facilitator.multiquestion.answerreset': 'Remove answer',
        'facilitator.multiquestion.answer': 'Answer:',
        'facilitator.multiquestion.answerscreen.waitingforanswer': 'Waiting for answer',
        'facilitator.multiquestion.answerscreen.ready': 'Ready',
        'facilitator.multiquestion.debatemoderation.title': 'Explanation',
        'facilitator.multiquestion.debatemoderation.speaking': 'The team currently speaking:',
        'facilitator.multiquestion.debatemoderation.accepted': 'Accepted',
        'facilitator.multiquestion.debatemoderation.declined': 'Rejected',
        'facilitator.multiquestion.debatemoderation.instruction': 'Hear the team’s explanation',
        'facilitator.multiquestion.answer.answer': 'Wait while players are discussing the clues and choosing their answer.',
        'facilitator.multiquestion.answer.result': 'Players are looking at the results.',
        //FACILITATOR VIEW MESSAGES END

        //SCREEN VIEW MESSAGES START

        //Start
        'screen.welcomescreen.title': 'Welcome',
        'screen.welcomescreen.instruction': 'Go to pulmikas.net',

        //Fact question
        'screen.factquestion.answerScreen.title': 'Correct answer',

        //Multiple choice question
        'screen.multiquestion.debatescreen.instruction': 'Please explain why you thought the option you chose was the best one',

        //Open question
        'screen.openquestion.debatescreen.instruction': 'Please explain your team’s answer',
        'facilitator.openquestion.vote': 'Players are voting for the best answer.',


        //Debate views
        'screen.debatewheel.title': 'Who gets to explain?',
        //SCREEN VIEW MESSAGES END

        //Universal
        'thanksforplaying': "Thank you for playing",
        'startbutton': 'Choose a game',
        'startgamebutton': 'Start a game',
        'startnewgame': 'Start a new game',
        'logout': 'Log out',
        'readybutton': 'Ready',
        'nextbutton': 'Next',
        'group': 'Team',
        'wait': 'Wait',
        'yes': 'Yes',
        'no': 'No',
        'hint': 'Clue',
        'discuss': 'Discuss',
        'listen': 'Listen',
        'debate': 'Explain',
        'forward': 'Forward',
        'send': 'Send',
        'press1second': 'Hold for half second',
        'waitMoment': 'Wait a moment',
        'errorMessage': 'Something went wrong, please try to log in again'
    }
  };