import React from 'react';
import {FormattedMessage, formatMessage} from 'react-intl';
import PropTypes from 'prop-types';
import confirmationMessage from 'utils/confirmationTranslation.ts';


export default class RateScreen extends React.Component {
    constructor(props){
        super(props);
        this.exitAndLogout = this.exitAndLogout.bind(this);
        this.exit = this.exit.bind(this);
    }
    exit() {
        this.props.sendSocketMessage({
            command: 'game.endGame',
        });
	}
	exitAndLogout() {

        if(window.confirm(confirmationMessage(this.state.language,'facilitator.logout.confirmation'))){
            if (document.cookie.indexOf('facilitator_TOKEN=') !== -1) {
                document.cookie = 'facilitator_TOKEN=' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }
			this.props.sendSocketMessage({
				command: 'game.endGame',
			});
		}
	}
    render() {
        return (
            <div className="app-container">
                <div className="container-fluid facilitator-container  scrollable-container">
                    <div className="row" style={{marginTop:'5rem'}}>
                            <div className="title-container col-md" style={{paddingTop:0, marginBottom:0}}>
                                <h1 className="app-title"><FormattedMessage id='thanksforplaying' /></h1>
                            </div>
                    </div>
                    <div className="row" style={{paddingTop:'3%'}}>
                        <div className="d-flex justify-content-center col-md">
                            <button
                                className="orange-button"
                                style={{marginTop:'35%', color: "#00c2a2"}}
                                onClick={() => {this.exit()}}
                            >
                                <FormattedMessage id='startnewgame' />
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <button
                                className="orange-button"
                                onClick={() => {this.exitAndLogout()}}
                            >
                                <FormattedMessage id='logout' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
RateScreen.contextTypes = {
  intl: PropTypes.object.isRequired
};
