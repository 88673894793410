import React from 'react';
import Websocket from 'react-websocket';
import _ from 'lodash';
import config from 'config.js'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    ScreenUserModuleHandler,
    PlayerTypeErrorHandler
} from './views/handlers.jsx';

//Actions
import * as LanguageActions from '../app/LanguageActions.js'

function getCookie(name) {
	  let value = "; " + document.cookie;
	  let parts = value.split("; " + name + "=");
	  if (parts.length == 2)
	  	return parts.pop().split(";").shift();
}

class ScreenGameApp extends React.Component {
	constructor(props) {
        super(props);
		let user_type = "screen";
		let instance_id = props.location.state && props.location.state.instance_id ? props.location.state.instance_id : "";
		let end_part = `?user_type=${user_type}&instance_id=${instance_id}&opintokamusimulaatiopeli_UID=`;


		if (document.cookie.indexOf('opintokamusimulaatiopeli_screen_UID=') !== -1){
			let cookie = getCookie('opintokamusimulaatiopeli_screen_UID');
			end_part = end_part + cookie;
		}

		let socket_url = `${config.SOCKETURL}` + end_part;

		this.state = {
			data: {},
			socket_url: socket_url,
            user_type: user_type
		};

		//bind send method
		this.sendSocketMessage = this.sendSocketMessage.bind(this);
	};

	sendSocketMessage(message){
        // sends message to channels back-end
       const socket = this.refs.socket;
       socket.state.ws.send(JSON.stringify(message));
    }

	handleSocketMessage(received_data) {
		let result = JSON.parse(received_data);

		// set session key to cookie
		if ('session_key' in result) {
			// Set cookie by session key
			let d = new Date();
			d.setTime(d.getTime() + (2*24*60*60*1000));
			let expires = "expires=" + d.toUTCString();
			document.cookie = 'opintokamusimulaatiopeli_screen_UID' + '=' + result['session_key'] + '; expires=' + d.toGMTString() + ';path=/;';
			let user_type = "screen";
			let instance_id = this.props.location.state && this.props.location.state.instance_id ? this.props.location.state.instance_id : "";
			let end_part = `?user_type=${user_type}&instance_id=${instance_id}&opintokamusimulaatiopeli_UID=${result['session_key']}`;
			let socket_url = `${config.SOCKETURL}` + end_part;

			this.setState({
				socket_url: socket_url,
			});
		}

		else {
            this.setState({data: result});
        }
	}
	handleOpenedSocketConnection() {
	    // Send player join
		this.sendSocketMessage(
            {
                command: 'groupBuilding.PlayerJoin'
            }
        )
	}
	render() {
		// Match user type
		let view = null;
		if (!_.isEmpty(this.state.data)) {
			switch (this.state.user_type) {
				case 'screen':
					view = (<ScreenUserModuleHandler
							{...this.props}
							information={this.state.data}
							sendSocketMessage={this.sendSocketMessage}
							/>);
					break;
				default:
					view = (<PlayerTypeErrorHandler
							information={this.state.data}
							sendSocketMessage={this.sendSocketMessage}
							/>);
			}
		}
		return (
			<div className="root">
				{view}
				<Websocket
					ref="socket"
					url={this.state.socket_url}
					onOpen={this.handleOpenedSocketConnection.bind(this)}
					onMessage={this.handleSocketMessage.bind(this)}
					debug={true}
				/>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, LanguageActions), dispatch);
}

export default connect(null, mapDispatchToProps)(ScreenGameApp);