import React from 'react';
import LanguageSelection from './LanguageSelection.jsx';
import AdminLoginView from './AdminLoginView.jsx';
import {Redirect} from 'react-router-dom';
import {bindActionCreators} from "redux";
import * as LanguageActions from "../../../app/LanguageActions";
import { connect } from 'react-redux';
import {assignLanguage} from "../../../utils/LanguageCheck";

function getCookie(name) {
    let value = "; " + document.cookie;
	let parts = value.split("; " + name + "=");
	if (parts.length == 2)
        return parts.pop().split(";").shift();
}

class AdminCheckLogin extends React.Component {
   constructor(props) {
        super(props);

        this.state = {
            logged: false,
            active_game_id: null,
            redirectToFrontPage: false,
        };
        this.logFacilitatorIn = this.logFacilitatorIn.bind(this);
        this.logFacilitatorOut = this.logFacilitatorOut.bind(this);
   }

    componentDidMount(){
        assignLanguage(this.props)
	}

    logFacilitatorIn(game_id=null) {
        this.setState({logged: true});
        if (game_id !== null) {
            this.setState({active_game_id: game_id});
        }
    }

    logFacilitatorOut() {
        if (document.cookie.indexOf('facilitator_TOKEN=') !== -1){
		    document.cookie = 'facilitator_TOKEN=' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
        this.setState({
            logged: false,
            redirectToFrontPage: true
        });

    }
    render(){
        let content;
        if (document.cookie.indexOf('facilitator_TOKEN=') !== -1) {
            this.state.logged = true;
		}

        if (this.state.redirectToFrontPage) {
              return <Redirect push to={{pathname: '/', state:{}}} />;
        }

        else if (this.state.logged == false) {
            content = (<AdminLoginView
                logIn={this.logFacilitatorIn}
            />);
        }
        else if (this.state.active_game_id !== null) {
            return <Redirect
                push to={
                    {pathname: '/game', state:{
                        user_type: 'facilitator',
                        instance_id: this.state.active_game_id
                    }}}
            />;
        }
        else {
            content = (<LanguageSelection
                logOut={this.logFacilitatorOut}
            />);
        }
        return (
            <div>
                {content}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, LanguageActions), dispatch);
}

export default connect(null, mapDispatchToProps)(AdminCheckLogin);