export const WELSH_STATE = {
    lang: 'cy',
    messages: {
        'app.gameselection.title':	'Gêm Pulmikas',
        'app.gameselection.gameid': 'Rhif adnabod (ID) y gêm',
        'app.gameselection.instruction': "Teipiwch y rhif adnabod a welwch ar y sgrin sy'n cael ei rhannu",
        'app.login.title': 'Mewngofnodi',
        'app.login.logout': 'Allgofnodi',
        'app.login.wrongcredentials': 'id neu cyfrinair anghywir',
        'app.login.password': 'cyfrinair',
        'app.login.username': 'Rhif Adnabod (ID)',
        'player.groupselection.title': 'Dewiswch dîm',
        'player.drawavatar.title': 'Gwnewch lun',
        'player.drawavatar.instruction': 'Gwnewch lun afatar i chi eich hun',
        'player.namegroup.title': "Enw'r tîm",
        'player.namegroup.instruction': "Dewiswch enw i'ch tîm gyda'ch gilydd",
        'player.factquestion.answer.title': 'Rhowch eich ateb',
        'facilitator.factquestion.answer':	'Arhoswch nes bod pob chwaraewr wedi ateb',
        'facilitator.factquestion.result':	"Mae'r chwaraewyr yn edrych ar yr atebion cywir",
        'player.openquestion.answerscreen.placeholder': 'Maint mwyaf: 100 nod',
        'screen.openquestion.answerscreen.choosebest':	 "Dewiswch yr ateb gorau o'r opsiynau canlynol",
        'player.openquestion.answerscreen.voteinstruction': 'Dewiswch yr ateb gorau',
        'facilitator.openquestion.answerscreen.answer': 'Arhoswch tra bo chwaraewyr yn trafod y cliwiau a rhoi eu hatebion',
        'player.ratescreen.title': 'Diolch am chwarae',
        'player.ratescreen.instruction': "Pa mor dda ydi'r gêm hon yn eich barn chi?",
        'facilitator.gameexit.confirmation': "Ydych chi'n siŵr eich bod am adael?",
        'facilitator.logout.confirmation': "Ydych chi'n siŵr eich bod am allgofnodi?",
        'facilitator.gameselect.title':	 'Dewiswch gêm',
        'facilitator.groupsize.title':	'Faint o dimau?',
        'facilitator.groupsize.instruction': 'Y nifer gorau mewn tîm yw 3-4 chwaraewr. Ni chaniateir mwy na 6',
        'facilitator.groupmoderation.title': 'Safoni',
        'facilitator.groupmoderation.instruction':	"Timau'n barod",
        'facilitator.groupmoderation.namereset': "Ailosod enw",
        'facilitator.groupmoderation.info': "Chwaraewyr yn ymuno â'r gêm. Dechreuwch y gêm pan fydd pawb mewn.",
        'facilitator.waitscreen.read': "Chwaraewyr yn darllen. Gallwch hefyd ddweud y testun yn uchel ar lafar",
        'facilitator.waitscreen.discuss': 'Chwaraewyr yn trafod cwestiwn',
        'facilitator.waitscreen.multiquestion.discuss': 'Chwaraewyr yn trafod. Pan symudwch ymlaen byddant yn cael dewisiadau a chliwiau ar gyfer yr atebion',
        'facilitator.waitscreen.openquestion.discuss':	'Chwaraewyr yn trafod. Pan symudwch ymlaen byddant yn cael dewisiadau a chliwiau ar gyfer yr atebion ',
        'facilitator.waitscreen.debatewheel': "Caiff y chwaraewr y gofynnir iddo egluro'r ateb ei ddewis ar hap.",
        'facilitator.waitscreen.voteresults': "Chwaraewyr yn edrych ar ganlyniadau'r bleidlais",
        'facilitator.waitscreen.debateresults':	"Chwaraewyr yn edrych ar y canlyniadau",
        'facilitator.setupbigscreen.title':	 "Sgrin sy'n cael ei rhannu",
        'facilitator.setupbigscreen.subtitle':	'Agorwch sgrin y gêm drwy we-lywio i:',
        'facilitator.setupbigscreen.description': 'pulmikas.net/cy/screen',
        'facilitator.setupbigscreen.screencode': 'Rhif adnabod (ID) y gêm',
        'facilitator.setupbigscreen.instructiontitle':	'Help:',
        'facilitator.setupbigscreen.instructioncontent-1':	"Rhaid i bob chwaraewr fedru gweld y sgrin sy'n cael ei rhannu",
        'facilitator.setupbigscreen.instructioncontent-2':	"Os oes modd ichi rannu sgrin y ddyfais hon agorwch sgrin mewn dyfais arall ac ewch i'r cyfeiriad gwe pulmikas.net/cy/screen.",
        'facilitator.setupbigscreen.instructioncontent-3':	"Os ydych yn defnyddio dyfais arall i rannu sgrin, ewch i'r cyfeiriad pulmikas.net/cy/screen ar y ddyfais honno a theipio'r rhif adnabod yn y ffenestr sy'n agor.",
        'facilitator.setupbigscreen.url': '/cy/screen',
        'facilitator.factquestion.answermoderation.answers': 'atebion',
        'facilitator.multiquestion.answerreset': "Cael gwared o'r ateb",
        'facilitator.multiquestion.answer':	 'Ateb:',
        'facilitator.multiquestion.answerscreen.waitingforanswer':	'Aros am ateb',
        'facilitator.multiquestion.answerscreen.ready':	 'Barod',
        'facilitator.multiquestion.debatemoderation.title':	 'Eglurhad',
        'facilitator.multiquestion.debatemoderation.speaking': "Y tîm sy'n siarad ar hyn o bryd:",
        'facilitator.multiquestion.debatemoderation.accepted':	'Derbyniwyd',
        'facilitator.multiquestion.debatemoderation.declined':	'Gwrthodwyd',
        'facilitator.multiquestion.debatemoderation.instruction':	'Clywed eglurhad y tîm',
        'facilitator.multiquestion.answer.answer': "Arhoswch tra bo'r chwaraewyr yn trafod y cliwiau a dewis eu hateb",
        'facilitator.multiquestion.answer.result': 'Y chwaraewyr yn edrych ar y canlyniadau.',
        'screen.welcomescreen.title': 'Croeso',
        'screen.welcomescreen.instruction':	 'Ewch i pulmikas.net',
        'screen.factquestion.answerScreen.title': 'Ateb cywir',
        'screen.multiquestion.debatescreen.instruction': 'Eglurwch pam fod yr opsiwn a ddewiswyd gennych yr un gorau yn eich barn chi',
        'screen.openquestion.debatescreen.instruction':	 'Eglurwch ateb eich tîm os gwelwch yn dda',
        'facilitator.openquestion.vote': 'Y chwaraewyr yn pleidleisio dros yr ateb gorau',
        'screen.debatewheel.title':	 "Pwy sy'n cael y dasg o egluro?",
        'thanksforplaying':	'Diolch am chwarae',
        'startbutton': 'Dewis gêm',
        'startgamebutton': 'Dechrau gêm',
        'startnewgame': 'Dechrau gêm newydd',
        'logout': 'Allgofnodi',
        'readybutton': 'Barod',
        'nextbutton': 'Nesaf',
        'group': 'Tîm',
        'wait': 'Arhoswch',
        'yes': 'Ie',
        'no': 'Na',
        'hint':	'Cliw',
        'discuss': 'Trafodwch',
        'listen': 'Gwrandewch',
        'debate': 'Eglurwch',
        'forward': 'Ymlaen',
        'send':	 'Anfonwch',
        'press1second': 'Daliwch am hanner eiliad',
        'waitMoment': 'Arhoswch am ychydig',
        'errorMessage':	 "Aeth rhywbeth o'i le. Ceisiwch fewngofnodi unwaith eto os gwelwch yn dda",
    }
};