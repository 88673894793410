import React, {Component} from 'react';
import leaveGame from "static/svg/leave-game.svg";
import plus from "static/svg/plus.svg";
import {Link} from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemBody,
} from 'react-accessible-accordion';
import { FormattedMessage } from 'react-intl';
import ActionButtons from '../../../../utils/FacilitatorActions.jsx';

export default class AnswerModeration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paused: false,
            disable_next: false,
        };
        this.nextState = this.nextState.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.resetAnswer = this.resetAnswer.bind(this);
    }

    nextState() {
        this.props.sendSocketMessage({
           command: 'game.next'
       });
       this.setState({disable_next: true});
       setTimeout(()=> this.setState({disable_next:false}), 5000);
    }

    resetAnswer(group) {
        this.props.sendSocketMessage({
           command: 'openQuestion.resetAnswer',
           data:{
               group: group,
           }
       });
    }

    handlePause(pause){
        this.setState({paused: pause})
    }

    render(){
        let {groups} = this.props.information.data;
        let groups_ready = 0;
        let amount_groups = Object.keys(groups).length;
        let groupList;
        let actionButtons;


        groupList = Object.keys(groups).map((index) => {
            let group = groups[index];
            let group_text = "";
            let name = group.name;

            if(group.name === null || group.name == ""){
                group_text = <FormattedMessage id = "group"/> 
                name = index
            }

            if(name.length > 13){
                name = name.slice(0,11) + '...'
            }
            // eslint-disable-next-line no-undef
            if (group.answer || _.isEmpty(group.players))
                groups_ready++;

            return (
                <div className="row" style={{marginLeft:'7.5%', marginRight:'7.5%'}}>
                    <div className="d-flex justify-content-center col-md">
                        <Accordion 
                        className="accordion-container"
                        >
                            <AccordionItem className="accordion-item">
                                <AccordionItemHeading
                                className="accordion-title-container"
                                >
                                    <AccordionItemButton>
                                    <h2 className="accordion-title" style={{display:'inline-block'}}>
                                        {group_text} {name}
                                    </h2>
                                    <span
                                    onClick={() => this.resetAnswer(index)}
                                    style={{display: 'inline-block', lineHeight:'6rem', float:'right', marginRight:'2rem', fontSize:'2rem', fontFamily:'Source Sans Pro'}} 
                                    className="accordion-icon">
                                    <FormattedMessage id='facilitator.multiquestion.answerreset' />
                                    <img
                                        style={{marginLeft:'1rem', height:'3.5rem', width:'3.5rem', transform:'rotate(45deg)'}}  
                                        src={plus}
                                    />
                                    </span>
                                </AccordionItemButton>
                                </AccordionItemHeading>
                                <p className="answer-text" style={{marginLeft:'4.5rem',paddingRight:'2rem'}}> {group.answer} </p>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            );
        });

        actionButtons = (
            <ActionButtons
                information={this.props.information}
                sendSocketMessage={this.props.sendSocketMessage}
            />
            );

        return (
            <div className ="app-container">
                <div className="container-fluid facilitator-container  scrollable-container" >
                    <span className="exit-button" onClick={() => this.props.exitGame() }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>      
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="app-title" style={{paddingTop:'1em'}}><FormattedMessage id='facilitator.groupmoderation.title'/></h1>
                        </div>
                    </div>
                     <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <p className="app-paragraph" style={{width: "70%", fontSize: "3rem", marginBottom: "3rem"}}><FormattedMessage id='facilitator.openquestion.answerscreen.answer'/></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <h1 className="app-title" >{groups_ready}/{amount_groups}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-flex justify-content-center col-md">
                            <p className="app-paragraph" style={{width:'70%', fontSize:'4rem', marginBottom:'3rem'}}><FormattedMessage id='facilitator.groupmoderation.instruction'/></p>
                        </div>
                    </div>
                    {groupList}
                    {actionButtons}  
                    <div className="padding" style={{paddingBottom:'35%'}}>
                    </div>      
                </div>
            </div>
        );
    }
}