export const ESTONIAN_STATE = {
    lang: 'ee',
    messages: {

        //PLAYER VIEW MESSAGES START
        'app.gameselection.title': 'Mäng "Pulmikas"',
        'app.gameselection.gameid': 'Mängu ID',
        'app.gameselection.instruction': 'Sisesta ühisel mängulaual kuvatav ID',

        'app.login.title': 'Logi sisse',
        'app.login.logout': 'Logi välja',
        'app.login.wrongcredentials': 'Vale ID või salasõna',
        'app.login.password': 'salasõna',
        'app.login.username': 'ID',

        'player.groupselection.title': 'Vali võistkond',
        'player.drawavatar.title': 'Joonista',
        'player.drawavatar.instruction': 'Joonista endale avatar',
        'player.namegroup.title': 'Võistkonna nimi',
        'player.namegroup.instruction': 'Pange võistkonnale koos nimi',

        //Fact question
        'player.factquestion.answer.title': 'Sisesta enda vastus',
        'facilitator.factquestion.answer': 'Oota, kuni kõik mängijad on vastanud.',
        'facilitator.factquestion.result': 'Mängijad kontrollivad õiget vastust.',

        //Open question
        'player.openquestion.answerscreen.placeholder': 'Maksimaalne pikkus: 100 tähemärki',
        'screen.openquestion.answerscreen.choosebest' : 'Vali järgmiste variantide seast sobivaim vastus',
        'player.openquestion.answerscreen.voteinstruction': 'Vali sobivaim vastus',
        'facilitator.openquestion.answerscreen.answer': 'Oota, kuni mängijad arutavad vihjeid ja sisestavad enda vastuse.',

        //Ending
        'player.ratescreen.title': 'Suur tänu mängimise eest!',
        'player.ratescreen.instruction': 'Missuguse hinnangu sellele mängule annad?',
        //PLAYER VIEW MESSAGES END


        //FACILITATOR VIEW MESSAGES START
        //Exit
        'facilitator.gameexit.confirmation': 'Kas oled kindel, et soovid mängust lahkuda?',
        'facilitator.logout.confirmation': 'Kas oled kindel, et soovid välja logida?',
        //Game select
        'facilitator.gameselect.title': 'Vali mäng',

        //Grouping
        'facilitator.groupsize.title': 'Mitu võistkonda?',
        'facilitator.groupsize.instruction': 'Soovituslik võistkonna suurus on 3–4 mängijat. Maksimaalne võistkondade arv on kuus.',
        'facilitator.groupmoderation.title': 'Mängujuht',
        'facilitator.groupmoderation.instruction': 'Võistkonnad on valmis',
        'facilitator.groupmoderation.namereset': 'Muuda nime',
        'facilitator.groupmoderation.info': 'Mängijad liituvad mänguga. Alusta mängu, kui kõik on liitunud.',

        //Facilitator wait screen
        'facilitator.waitscreen.read': 'Mängijad loevad. Võid teksti ka valjusti ette lugeda.',
        'facilitator.waitscreen.discuss': 'Mängijad arutavad küsimust.',
		'facilitator.waitscreen.multiquestion.discuss': 'Mängijad arutavad. Kui liigud edasi, kuvatakse mängijatele vastusevariandid ja vihjed.',
		'facilitator.waitscreen.openquestion.discuss': 'Mängijad arutavad. Kui liigud edasi, kuvatakse mängijatele vastuseväli ja vihjed.',
        'facilitator.waitscreen.debatewheel': 'Mängija, kes peab vastust selgitama, valitakse juhuslikult.',
        'facilitator.waitscreen.voteresults': 'Mängijad vaatavad hääletamise tulemusi.',
        'facilitator.waitscreen.debateresults': 'Mängijad vaatavad tulemusi.',

        //Big screen setup
        'facilitator.setupbigscreen.title': 'Ühine mängulaud',
        'facilitator.setupbigscreen.subtitle': 'Mängulaua avamiseks ava:',
        'facilitator.setupbigscreen.description': 'pulmikas.net/ee/screen',
        'facilitator.setupbigscreen.screencode': 'Mängu ID',
        'facilitator.setupbigscreen.instructiontitle': 'Abi:',
        'facilitator.setupbigscreen.instructioncontent-1': 'Ühine mängulaud peab olema kõigile mängijatele nähtav.',
        'facilitator.setupbigscreen.instructioncontent-2': 'Kui selle seadme ekraanikuva on võimalik jagada, ava teine veebilehitseja aken ning mine aadressile pulmikas.net/ee/screen.',
        'facilitator.setupbigscreen.instructioncontent-3': 'Kui kasutad ekraanikuva jagamiseks mõnda teist seadet, ava sellel seadmel veebileht pulmikas.fi/screen ja sisesta avanevas aknas ID.',
        'facilitator.setupbigscreen.url': "/ee/screen",
        //Fact question
        'facilitator.factquestion.answermoderation.answers': 'vastused',

        //Multi question
        'facilitator.multiquestion.answerreset': 'Kustuta vastus',
        'facilitator.multiquestion.answer': 'Vastus:',
        'facilitator.multiquestion.answerscreen.waitingforanswer': 'Ootan vastust',
        'facilitator.multiquestion.answerscreen.ready': 'Valmis',
        'facilitator.multiquestion.debatemoderation.title': 'Selgitus',
        'facilitator.multiquestion.debatemoderation.speaking': 'Praegu räägib võistkond:',
        'facilitator.multiquestion.debatemoderation.accepted': 'Vastu võetud',
        'facilitator.multiquestion.debatemoderation.declined': 'Tagasi lükatud',
        'facilitator.multiquestion.debatemoderation.instruction': 'Kuula võistkonna selgitust',
        'facilitator.multiquestion.answer.answer': 'Oota, kuni mängijad arutavad vihjeid ja valivad enda vastuse.',
        'facilitator.multiquestion.answer.result': 'Mängijad vaatavad tulemusi.',
        //FACILITATOR VIEW MESSAGES END

        //SCREEN VIEW MESSAGES START

        //Start
        'screen.welcomescreen.title': 'Tere tulemast',
        'screen.welcomescreen.instruction': 'Ava veebileht pulmikas.net',

        //Fact question
        'screen.factquestion.answerScreen.title': 'Õige vastus',

        //Multiple choice question
        'screen.multiquestion.debatescreen.instruction': 'Palun selgita, miks arvad, et valitud variant on kõige õigem?',

        //Open question
        'screen.openquestion.debatescreen.instruction': 'Palun selgita enda võistkonna vastust',
        'facilitator.openquestion.vote': 'Mängijad valivad parima vastuse.',


        //Debate views
        'screen.debatewheel.title': 'Kes hakkab selgitama?',
        //SCREEN VIEW MESSAGES END

        //Universal
        'thanksforplaying': "Suur tänu mängimise eest!",
        'startbutton': 'Vali mäng',
        'startgamebutton': 'Alusta mängu',
        'startnewgame': 'Alusta uut mängu',
        'logout': 'Logi välja',
        'readybutton': 'Valmis',
        'nextbutton': 'Järgmine',
        'group': 'Võistkond',
        'wait': 'Oota',
        'yes': 'Jah',
        'no': 'Ei',
        'hint': 'Vihje',
        'discuss': 'Aruta',
        'listen': 'Kuula',
        'debate': 'Selgita',
        'forward': 'Edasi',
        'send': 'Saada',
        'press1second': 'Hoia kaks sekundit all',
        'waitMoment': 'Oota üks hetk',
        'errorMessage': 'Midagi läks valesti, palun proovi uuesti sisse logida'
    }
  };