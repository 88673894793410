export function assignLanguage(props) {
    if (props.match.params.lng) {
        if (props.match.params.lng === 'se') {
            props.changeLanguageSwedish();
        }
        if (props.match.params.lng === 'en') {
            props.changeLanguageEnglish();
        }

        if (props.match.params.lng === 'cy') {
            props.changeLanguageWelsh();
        }
        if (props.match.params.lng === 'nl') {
            props.changeLanguageDutch();
        }
        if (props.match.params.lng === 'cs') {
            props.changeLanguageCzech();
        }
        if (props.match.params.lng === 'eu') {
            props.changeLanguageBasque();
        }
        if (props.match.params.lng === 'ee') {
            props.changeLanguageEstonian();
        }
        if (props.match.params.lng === 'ru') {
            props.changeLanguageRussian();
        }
        if(window) {
            window.document.documentElement.lang = props.match.params.lng;
        }
    }
}