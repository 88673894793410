import React, {Component} from 'react';
import leaveGame from "static/svg/leave-game.svg";
import {FormattedMessage} from 'react-intl';
import Slider from 'rc-slider';
import WaitingScreen from '../WaitingScreen.jsx';

//Styles
import 'rc-slider/assets/index.css';


export default class FactQuestionAnswer extends Component {
    constructor(props) {
        super(props);

    }
    render(){
        let viewComponent;
        let viewName = this.props.information.view.split('.').slice(-1)[0];
        if(viewName=="yesNoAnswer"){
            viewComponent = (
                <OptionAnswer
                    information={this.props.information}
                    sendSocketMessage={this.props.sendSocketMessage}
                    module="factQuestion"
                    exitGame={this.props.exitGame}
                />
            )
        }
        else if(viewName=="numberAnswer"){
            viewComponent = (
                <NumberAnswer
                    information={this.props.information}
                    sendSocketMessage={this.props.sendSocketMessage}
                    module="factQuestion"
                    exitGame={this.props.exitGame}
                />
            )
        }
        else {
            viewComponent = (
                <WaitingScreen
                    information={this.props.information}
                    sendSocketMessage={this.props.sendSocketMessage}
                    module="factQuestion"
                    exitGame={this.props.exitGame}
                />
            )
        }
        return (
            <div>
                {viewComponent}
            </div>
        );
    }
}

class NumberAnswer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            answer: 0,
            screenWidth: null,
        };

        this.handleAnswer = this.handleAnswer.bind(this);
        this.sendAnswer = this.sendAnswer.bind(this);
    }

    componentDidMount(){
        let answer;
        if(this.props.information.data.min_answer >= 0){
            answer = this.props.information.data.min_answer;
        }
        else {
            answer = 0;
        }

        this.setState({answer});
        this.setState({screenWidth: window.innerWidth})
    }

    sendAnswer(value){
        this.props.sendSocketMessage({
            command: 'factQuestion.playerAnswer',
            data: {
                answer: value,
            }
        });
    }

    handleAnswer(_value){
        let {data} = this.props.information;
        let value = _value;
        try{
            value = parseInt(value)
        }catch(e){
            this.setState({answer: "" })
            return;
        }
        if(isNaN(value)){
            this.setState({answer: "" })
        }
        else if( value > data.max_answer){
            this.setState({answer: data.max_answer })
        }
        else if( value < data.min_answer){
            this.setState({answer: data.min_answer })
        }
        else{
            this.setState({answer: value })
        }
    }

    render() {
        let {data} = this.props.information;
        let handleStyle;

        if(this.state.screenWidth > 1000){
            handleStyle={width:'5rem', height:'5rem', top:'-1rem', border:'0'}
        } else {
            handleStyle={width:'5rem', height:'5rem', top:'-1.5rem', border:'0'}
        }
        return (
            <div className ="app-container">
                <div className="container-fluid scrollable-container player-container">
                    <span className="exit-button" onClick={() => this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>

                    <div className="row">
                        <div className="col-md">
                            <h1 className="app-title" style={{paddingLeft:'27%', paddingRight:'27%', paddingTop:'1em', paddingBottom:'1em'}}><FormattedMessage id='player.factquestion.answer.title'/></h1>
                        </div>
                    </div>
                    <div className="row" style={{paddingTop:'10rem'}}>
                        <div className="d-flex justify-content-center col-md">
                            <input className="game-select"
                                type="number"
                                maxLength="1" 
                                style={{width:'4em'}}
                                onChange={this.handleAnswer} 
                                value={this.state.answer} 
                                step={data.slider_step}
                                disabled
                             />
                        </div>
                    </div>
                    <div className="row" style={{paddingTop:'8rem', paddingBottom:'5em'}}>
                        <div className="d-flex justify-content-center col-md">
                             <Slider
                             style={{width:'80%'}}
                             handleStyle={handleStyle}
                             trackStyle={{height:'0.6em', backgroundColor:'white'}}
                             railStyle={{height:'0.6em', opacity:'0.5'}}
                             min={data.min_answer}
                             max={data.max_answer}
                             step={data.slider_step}
                             defaultValue={data.min_answer}
                             onChange={this.handleAnswer}
                             value={this.state.answer >= 0 ? this.state.answer : data.min_answer}/>

                        </div>
                    </div>
                    <div className="row" style={{paddingTop:'20%'}}>
                        <div className="col-md">
                                <button className="orange-button" onClick={() => this.sendAnswer(this.state.answer)}><FormattedMessage id='readybutton'/></button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

class OptionAnswer extends Component{
    constructor(props) {
        super(props);
        this.sendAnswer = this.sendAnswer.bind(this);
    }
    sendAnswer(value){
        this.props.sendSocketMessage({
            command: 'factQuestion.playerAnswer',
            data: {
                answer: value,
            }
        });
    }
    render() {
        return (
            <div className="app-container">
                <div className="container-fluid player-container" style={{overflowY: 'auto'}}>
                    <span className="exit-button" onClick={() => this.props.exitGame(this.props.module) }><img style={{width:"100%", height:"100%"}} src={leaveGame}/></span>

                    <div className="row">
                        <div className="col-md">
                            <h1 className="app-title" style={{paddingLeft:'27%', paddingRight:'27%', paddingTop:'1em', paddingBottom:'1em'}}><FormattedMessage id='player.factquestion.answer.title'/></h1>
                        </div>
                    </div>
                    <div className="row" style={{paddingTop:'10rem', paddingBottom:'5rem'}}>
                        <div className="d-flex justify-content-center col-md">
                                <button className="answer-button" style={{color:'green'}} onClick={() => this.sendAnswer('yes')}><FormattedMessage id='yes'/></button>
                                <button className="answer-button" style={{color:'red'}} onClick={() => this.sendAnswer('no')}><FormattedMessage id='no'/></button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}