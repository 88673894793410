import { FINNISH_STATE } from "static/locales/fi.js";
import { SWEDISH_STATE } from "static/locales/se.js";
import { ENGLISH_STATE } from "static/locales/en.js";
import { WELSH_STATE } from "static/locales/cy.js";
import { DUTCH_STATE } from "static/locales/nl.js";
import { CZECH_STATE } from "static/locales/cs.js";
import { BASQUE_STATE } from "static/locales/eu.js";
import { ESTONIAN_STATE } from "static/locales/ee.js";
import { RUSSIAN_STATE } from "static/locales/ru.js";

const confirmationMessage = (languageCode, translationKey) => {
    switch (languageCode) {
        case "fi":
            return FINNISH_STATE.messages[translationKey];
        case "se":
            return SWEDISH_STATE.messages[translationKey];
        case "en":
            return ENGLISH_STATE.messages[translationKey];
        case "cy":
            return WELSH_STATE.messages[translationKey];
        case "nl":
            return DUTCH_STATE.messages[translationKey];
        case "cs":
            return CZECH_STATE.messages[translationKey];
        case "eu":
            return BASQUE_STATE.messages[translationKey];
        case "ee":
            return ESTONIAN_STATE.messages[translationKey];
        case "ru":
            return RUSSIAN_STATE.messages[translationKey];
        default:
            return FINNISH_STATE.messages[translationKey];
    }
};

export default confirmationMessage;
