import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import GameSetup from './GameSetup.jsx';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
//Actions
import * as LanguageActions from '../../../app/LanguageActions.js';
import PropTypes from 'prop-types';
import confirmationMessage from '../../../utils/confirmationTranslation.ts'

class SelectLanguage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: null,
        };

        this.selectLanguage = this.selectLanguage.bind(this);
        this.logFacilitatorOut = this.logFacilitatorOut.bind(this);
    }

    componentDidMount() {
        if(this.props.lang) {
            this.setState({language: this.props.lang.lang});
        }
    }

    logFacilitatorOut() {
        if(window.confirm(confirmationMessage(this.state.language,'facilitator.logout.confirmation'))){
            this.props.logOut();
        }
    }

    selectLanguage(lang) {
        this.state.language = lang;
        LanguageActions.runLanguageChangeAction(this, lang);
    }
    render(){
        let languages = {
            "fi": "Suomeksi",
            "se": "Svenska",
        };
        let content;
        let languageButtons = Object.keys(languages).map( (language,i )=>
            <div key={'row'+String(i)} className="row button-row">
            <div key={'col'+String(i)} className="d-flex justify-content-center col-md">
                <Button className="group-button big-button" key = {i} disabled = {this.state.sent} onClick = {() => this.selectLanguage(language)} >
                    {languages[language]}
                </Button>
            </div>
        </div>);
        if(this.state.language === null){
            content = (
            <div className="container-fluid" style={{overflowY: 'auto'}}>
                <div className="row">
                    <div className="title-container col-md">
                        <h1 className="app-title">Valitse kieli/välj ditt språk</h1>
                    </div>
                </div>
                {languageButtons}
                <div className="row" style={{marginTop: '25%'}}>
                    <div className="d-flex justify-content-center col-md">
                        <button
                            className="orange-button"
                            onClick={() => {this.logFacilitatorOut()}}
                        >
                            <FormattedMessage id='logout' />
                        </button>
                    </div>
                </div>
            </div>
            )
        }
        else {
            content=(<GameSetup
                language={this.state.language}
                logOut = {this.props.logOut }
            />)
        }
        return (
            <div className="app-container">
            {content}
            </div>
        );
    }
}

SelectLanguage.contextTypes = {
  intl: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
      lang: state.languagesReducer,
    };
  }
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, LanguageActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectLanguage);
