import React from 'react';
import PropTypes from 'prop-types';

//Facilitator views
import FacilitatorGroupBuilding from './facilitatorviews/GroupBuildingHandler.jsx';
import FacilitatorFactQuestionHandler from './facilitatorviews/factquestion/FactQuestionHandler.jsx';
import FacilitatorMultiQuestionHandler from './facilitatorviews/multiquestion/MultiQuestionHandler.jsx';
import FacilitatorOpenQuestionHandler from './facilitatorviews/openquestion/OpenQuestionHandler.jsx';
import FacilitatorSliderHandler from './facilitatorviews/slider/SliderHandler.jsx';
import FacilitatorGameEndHandler from './facilitatorviews/gameend/GameEndHandler.jsx';
import FacilitatorResetHandler from './facilitatorviews/End.jsx';

//Player views
import PlayerGroupBuildingHandler from './playerviews/groupbuilding.jsx';
import PlayerMultiQuestionHandler from './playerviews/multiquestion/MultiQuestionHandler.jsx';
import FactQuestionAnswer from './playerviews/factquestion/FactQuestionAnswer.jsx';
import WaitingScreen from './playerviews/WaitingScreen.jsx';
import PlayerInstructionScreen from './playerviews/InstructionScreen.jsx';
import PlayerOpenQuestionHandler from './playerviews/openquestion/OpenQuestionHandler.jsx';
import PlayerSliderHandler from './playerviews/slider/SliderHandler.jsx';
import PlayerGameEndHandler from './playerviews/gameend/GameEndHandler.jsx';
import PlayerResetHandler from './playerviews/End.jsx';

//Screen views
import ScreenWelcome from './screenviews/WelcomeScreen.jsx';
import GameStart from './screenviews/GameStart.jsx';
import ScreenFactQuestionHandler from './screenviews/factquestion/FactQuestionHandler.jsx';
import ScreenMultiQuestionHandler from './screenviews/multiquestion/MultiQuestionHandler.jsx';
import ScreenOpenQuestionHandler from './screenviews/openquestion/OpenQuestionHandler.jsx';
import ScreenSliderHandler from './screenviews/slider/SliderHandler.jsx';
import ScreenGameEndHandler from './screenviews/gameend/GameEndHandler.jsx';
import ScreenResetHandler from './screenviews/End.jsx';
import { runLanguageChangeAction } from "../../app/LanguageActions.js";

import confirmationMessage from 'utils/confirmationTranslation.ts';

class PlayerModuleHandler extends React.Component {
	constructor(props) {
        super(props);
			this.state={
				language: null,
			};
        this.exitGame = this.exitGame.bind(this);

	}

	componentDidMount(){
		//Language selection from backend
		let language = this.props.information.language ? this.props.information.language : null;
		this.setState({language});
        runLanguageChangeAction(this, language);
	}

    exitGame(module){
        if(window.confirm(confirmationMessage(this.state.language,'facilitator.gameexit.confirmation'))){
			this.props.sendSocketMessage({
				command: module + '.playerExit',
			});
		}
	}
    render() {
        let moduleView = null;
        // Get the module name from view string
		let moduleName = this.props.information.view.split('.')[0];
        switch(moduleName) {
			case 'groupBuilding':
				moduleView = (
				    <PlayerGroupBuildingHandler
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
                    />
                );
				break;
			case 'factQuestion':
				moduleView = (
					<FactQuestionAnswer
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;

			case 'multiQuestion':
				moduleView = (
					<PlayerMultiQuestionHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;

			case 'openQuestion':
				moduleView = (
					<PlayerOpenQuestionHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;

			case 'waitingScreen':
				moduleView = (
					<WaitingScreen
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;

			case 'instructionScreen':
				moduleView = (
					<PlayerInstructionScreen
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;

			case 'gameEnd':
				moduleView = (
					<PlayerGameEndHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
            case 'slider':
                moduleView = (
					<PlayerSliderHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
                break;
			case 'reset':
				moduleView = (
					<PlayerResetHandler />
				);
				break;
            default:
				moduleView = null;
		}
		return (<div>{moduleView}</div>);
	}
}

PlayerModuleHandler.contextTypes = {
  intl: PropTypes.object.isRequired
};

class FacilitatorModuleHandler extends React.Component {
    constructor(props) {
        super(props);
        this.exitGame = this.exitGame.bind(this);
    }

	componentDidMount(){
		//Language selection from backend
		let language = this.props.information.language ? this.props.information.language : null;
		this.setState({language});
        runLanguageChangeAction(this, language);
	}

    exitGame(){
        if(window.confirm(confirmationMessage(this.state.language,'facilitator.gameexit.confirmation'))){
			this.props.sendSocketMessage({
				command: 'game.endGame',
			});
		}
	}

    render() {
        let moduleView = null;
        // Get the module name from view string
		let moduleName =  this.props.information.view.split('.')[0];
		switch(moduleName) {
			case 'groupBuilding':
				moduleView = (
				    <FacilitatorGroupBuilding
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
                    />
                );
				break;
			case 'factQuestion':
				moduleView = (
					<FacilitatorFactQuestionHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
			case 'multiQuestion':
				moduleView = (
					<FacilitatorMultiQuestionHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
			case 'openQuestion':
				moduleView = (
					<FacilitatorOpenQuestionHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
			case 'gameEnd':
				moduleView = (
					<FacilitatorGameEndHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
            case 'slider':
                moduleView = (
					<FacilitatorSliderHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
                break;
			case 'reset':
				moduleView = (
					<FacilitatorResetHandler />
				);
				break;
            default:
				moduleView = null;
		}
		return (<div>{moduleView}</div>);
	}
}

FacilitatorModuleHandler.contextTypes = {
  intl: PropTypes.object.isRequired
};

class ScreenUserModuleHandler extends React.Component {
	constructor(props) {
        super(props);
	}

	componentDidMount(){

		//Language selection from backend
		let language = this.props.information.language ? this.props.information.language : null;

		this.setState({language});
        runLanguageChangeAction(this, language);
	}


    render() {
        let moduleView = null;
        // Get the module name from view string
		let moduleName = this.props.information.view.split('.')[0];
		switch(moduleName) {
			case 'groupBuilding':
				moduleView = (
				    <ScreenWelcome
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
                    />
                );
				break;
			case 'gameStart':
				moduleView = (
					<GameStart
                        information={this.props.information}
                        sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
			case 'factQuestion':
				moduleView = (
					<ScreenFactQuestionHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
			case 'multiQuestion':
				moduleView = (
					<ScreenMultiQuestionHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
			case 'openQuestion':
				moduleView = (
					<ScreenOpenQuestionHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
			case 'gameEnd':
				moduleView = (
					<ScreenGameEndHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
				break;
			case 'slider':
                moduleView = (
					<ScreenSliderHandler
						information={this.props.information}
						sendSocketMessage={this.props.sendSocketMessage}
                    	exitGame={this.exitGame}
					/>
				);
                break;
			case 'reset':
				moduleView = (
					<ScreenResetHandler />
				);
				break;

            default:
				moduleView = null;
		}
		return (<div>{moduleView}</div>);
	}
}

class PlayerTypeErrorHandler extends React.Component {
	render() {
		return (
			<div><strong>Error, unidentified user type</strong></div>
		);
	}
}

export {
    PlayerModuleHandler,
    FacilitatorModuleHandler,
    ScreenUserModuleHandler,
    PlayerTypeErrorHandler
}