/**
 * This handles all language select -actions
 */

export function changeLanguageFinnish(){
    return (dispatch, getState) => dispatch({
        type: 'LANGUAGE_FINNISH',
    });
}

export function changeLanguageSwedish() {
    return (dispatch, getState) => dispatch({
        type: 'LANGUAGE_SWEDISH',
    });
}

export function changeLanguageEnglish(){
    return (dispatch, getState) => dispatch({
        type: 'LANGUAGE_ENGLISH',
    });
}

export function changeLanguageWelsh() {
     return (dispatch, getState) => dispatch({
        type: 'LANGUAGE_WELSH',
    });
}

export function changeLanguageDutch() {
     return (dispatch, getState) => dispatch({
        type: 'LANGUAGE_DUTCH',
    });
}

export function changeLanguageCzech() {
    return (dispatch, getState) => dispatch({
       type: 'LANGUAGE_CZECH',
   });
}

export function changeLanguageBasque() {
    return (dispatch, getState) => dispatch({
       type: 'LANGUAGE_BASQUE',
   });
}

export function changeLanguageEstonian() {
    return (dispatch, getState) => dispatch({
       type: 'LANGUAGE_ESTONIAN',
   });
}

export function changeLanguageRussian() {
    return (dispatch, getState) => dispatch({
       type: 'LANGUAGE_RUSSIAN',
   });
}
  
  // helper function for running the languageChange action,
  // used to avoid maintenance hell of N identical if-else-chains
export function runLanguageChangeAction(object, languageCode) {
    if (!object) {
        return;
    }
    const objectProps = object.props ? object.props : null;

    // do NOT call anything dynamically if the input object does not have props
    if (objectProps === null) {
        return;
    }

    const languageMap = {
        fi: "changeLanguageFinnish",
        se: "changeLanguageSwedish",
        en: "changeLanguageEnglish",
        cy: "changeLanguageWelsh",
        nl: "changeLanguageDutch",
        cs: "changeLanguageCzech",
        eu: "changeLanguageBasque",
        ee: "changeLanguageEstonian",
        ru: "changeLanguageRussian",
    };

    // default to finnish if the lang code does not match any key:
    if (!Object.keys(languageMap).includes(languageCode)) {
        objectProps.changeLanguageFinnish();
        return;
    }

    objectProps[languageMap[languageCode]]();
    }