export const CZECH_STATE = {
    lang: 'cs',
    messages: {

        //PLAYER VIEW MESSAGES START
        'app.gameselection.title': 'Hra Pulmikas',
        'app.gameselection.gameid': 'ID hry',
        'app.gameselection.instruction': 'Zadejte ID ze společné obrazovky',

        'app.login.title': 'Přihlásit se',
        'app.login.logout': 'Odhlásit se',
        'app.login.wrongcredentials': 'Nesprávné ID nebo heslo!',
        'app.login.password': 'heslo',
        'app.login.username': 'ID',

        'player.groupselection.title': 'Zvolit tým',
        'player.drawavatar.title': 'Kreslit',
        'player.drawavatar.instruction': 'Nakreslit avatara',
        'player.namegroup.title': 'Jméno týmu',
        'player.namegroup.instruction': 'Společně svůj tým pojmenujte',

        //Fact question
        'player.factquestion.answer.title': 'Zvolte odpověď',
        'facilitator.factquestion.answer': 'Počkejte, dokud neodpoví všichni hráči.',
        'facilitator.factquestion.result': 'Hráči si čtou správnou odpověď.',

        //Open question
        'player.openquestion.answerscreen.placeholder': 'Maximální délka: 100 znaků',
        'screen.openquestion.answerscreen.choosebest' : 'Zvolte nejlepší odpověď z následujících možností',
        'player.openquestion.answerscreen.voteinstruction': 'Zvolte nejlepší odpověď',
        'facilitator.openquestion.answerscreen.answer': 'Počkejte, až ostatní hráči prodiskutují nápovědy a zadají odpověď.',
        //Ending
        'player.ratescreen.title': 'Děkujeme, že jste si zahráli.',
        'player.ratescreen.instruction': 'Jak byste tuto hru ohodnotili?',
        //PLAYER VIEW MESSAGES END


        //FACILITATOR VIEW MESSAGES START
        //Exit
        'facilitator.gameexit.confirmation': 'Opravdu chcete hru ukončit?',
        'facilitator.logout.confirmation': 'Opravdu se chcete odhlásit?',
        //Game select
        'facilitator.gameselect.title': 'Zvolit hru',

        //Grouping
        'facilitator.groupsize.title': 'Kolik týmů bude hrát?',
        'facilitator.groupsize.instruction': 'Doporučený počet členů jednoho týmu je 3–4. Maximální počet týmů je 6.',
        'facilitator.groupmoderation.title': 'Moderátor',
        'facilitator.groupmoderation.instruction': 'Týmy připraveny',
        'facilitator.groupmoderation.namereset': 'Znovu nastavit jméno',
        'facilitator.groupmoderation.info': 'Hráči se připojují. Až budou všichni připraveni, můžete hru zahájit.',

        //Facilitator wait screen
        'facilitator.waitscreen.read': 'Hráči čtou zadání. Můžete jim ho také přečíst nahlas.',
        'facilitator.waitscreen.discuss': 'Hráči diskutují o otázce.',
		'facilitator.waitscreen.multiquestion.discuss': 'Hráči diskutují. Když kliknete na „Dále“, zobrazí se jim možnosti odpovědí a nápovědy.',
		'facilitator.waitscreen.openquestion.discuss': 'Hráči diskutují. Když kliknete na „Dále“, zobrazí se textové pole pro zadání odpovědi a nápovědy.',
        'facilitator.waitscreen.debatewheel': 'Hráč, který má odpověď vysvětlit, je zvolen náhodně.',
        'facilitator.waitscreen.voteresults': 'Hráči si prohlíží výsledky hlasování.',
        'facilitator.waitscreen.debateresults': 'Hráči si prohlíží výsledky.',

        //Big screen setup
        'facilitator.setupbigscreen.title': 'Společná obrazovka',
        'facilitator.setupbigscreen.subtitle': 'Hru otevřete načtením stránky:',
        'facilitator.setupbigscreen.description': 'pulmikas.net/cs/screen',
        'facilitator.setupbigscreen.screencode': 'ID hry',
        'facilitator.setupbigscreen.instructiontitle': 'Nápověda:',
        'facilitator.setupbigscreen.instructioncontent-1': 'Na společnou obrazovku musí vidět všichni hráči.',
        'facilitator.setupbigscreen.instructioncontent-2': 'Pokud můžete všem hráčům promítnout obrazovku tohoto zařízení, otevřete si na něm nové okno prohlížeče a načtěte v něm stránku pulmikas.net/cs/screen',
        'facilitator.setupbigscreen.instructioncontent-3': 'Pokud společnou obrazovku sdílíte na jiném zařízení, načtěte na něm stránkupulmikas.net/cs/screen a zadejte ID hry.',
        'facilitator.setupbigscreen.url': "/cs/screen",

        //Fact question
        'facilitator.factquestion.answermoderation.answers': 'odpovědi',

        //Multi question
        'facilitator.multiquestion.answerreset': 'Smazat odpověď',
        'facilitator.multiquestion.answer': 'Odpověď:',
        'facilitator.multiquestion.answerscreen.waitingforanswer': 'Čekání na odpověď',
        'facilitator.multiquestion.answerscreen.ready': 'Hotovo',
        'facilitator.multiquestion.debatemoderation.title': 'Vysvětlení',
        'facilitator.multiquestion.debatemoderation.speaking': 'Tým, který právě mluví:',
        'facilitator.multiquestion.debatemoderation.accepted': 'Přijato',
        'facilitator.multiquestion.debatemoderation.declined': 'Odmítnuto',
        'facilitator.multiquestion.debatemoderation.instruction': 'Vysvětlení týmu',
        'facilitator.multiquestion.answer.answer': 'Počkejte, až ostatní hráči prodiskutují nápovědy a zvolí odpověď.',
        'facilitator.multiquestion.answer.result': 'Hráči si prohlíží výsledky.',
        //FACILITATOR VIEW MESSAGES END

        //SCREEN VIEW MESSAGES START

        //Start
        'screen.welcomescreen.title': 'Vítejte',
        'screen.welcomescreen.instruction': 'Přejděte na stránku pulmikas.net',

        //Fact question
        'screen.factquestion.answerScreen.title': 'Správná odpověď',

        //Multiple choice question
        'screen.multiquestion.debatescreen.instruction': 'Vysvětlete, proč byla možnost, kterou jste zvolili, podle vašeho názoru nejlepší.',

        //Open question
        'screen.openquestion.debatescreen.instruction': 'Vysvětlete svou odpověď.',
        'facilitator.openquestion.vote': 'Hráči hlasují, která odpověď byla nejlepší.',


        //Debate views
        'screen.debatewheel.title': 'Kdo bude vysvětlovat?',
        //SCREEN VIEW MESSAGES END

        //Universal
        'thanksforplaying': 'Děkujeme, že jste si zahráli.',
        'startbutton': 'Zvolte si hru',
        'startgamebutton': 'Začít hrát',
        'startnewgame': 'Začít hrát novou hru',
        'logout': 'Odhlásit se',
        'readybutton': 'Hotovo',
        'nextbutton': 'Dále',
        'group': 'Tým',
        'wait': 'Počkejte, prosím',
        'yes': 'Ano',
        'no': 'Ne',
        'hint': 'Nápověda',
        'discuss': 'Diskutujte',
        'listen': 'Poslouchejte',
        'debate': 'Vysvětlete',
        'forward': 'Dále',
        'send': 'Odeslat',
        'press1second': 'Podržte 2 sekundy',
        'waitMoment': 'Počkejte, prosím',
        'errorMessage': 'Něco se pokazilo, zkuste se prosím přihlásit znovu',
    }
  };