export const DUTCH_STATE = {
    lang: 'nl',
    messages: {

        //PLAYER VIEW MESSAGES START
        'app.gameselection.title': 'Pulmikas-spel',
        'app.gameselection.gameid': 'Spel-ID',
        'app.gameselection.instruction': 'Voer de ID in die op het gedeelde scherm wordt weergegeven',

        'app.login.title': "Inloggen",
        'app.login.logout': "Uitloggen",
        'app.login.wrongcredentials': "ID of wachtwoord ongeldig!",
        'app.login.password': 'wachtwoord',
        'app.login.username': 'ID',

        'player.groupselection.title': 'Kies een team',
        'player.drawavatar.title': 'Maak een tekening',
        'player.drawavatar.instruction': 'Teken een avatar voor jezelf',
        'player.namegroup.title': 'Naam van het team',
        'player.namegroup.instruction': 'Kies samen een naam voor jullie team',

        //Fact question
        'player.factquestion.answer.title': 'Voer je antwoord in',
        'facilitator.factquestion.answer': 'Wacht tot alle spelers hebben geantwoord.',
        'facilitator.factquestion.result': 'De spelers bekijken het juiste antwoord.',

        //Open question
        'player.openquestion.answerscreen.placeholder': 'Max. 100 tekens',
        'screen.openquestion.answerscreen.choosebest' : 'Kies het beste antwoord uit de volgende opties',
        'player.openquestion.answerscreen.voteinstruction': 'Kies het beste antwoord',
        'facilitator.openquestion.answerscreen.answer': 'Wacht terwijl de spelers de tips bespreken en hun antwoord invoeren.',

        //Ending
        'player.ratescreen.title': 'Bedankt om mee \nte spelen',
        'player.ratescreen.instruction': 'Hoe zou je dit spel beoordelen?',
        //PLAYER VIEW MESSAGES END


        //FACILITATOR VIEW MESSAGES START
        //Exit
        'facilitator.gameexit.confirmation': 'Weet je zeker dat je wil stoppen?',
        'facilitator.logout.confirmation': 'Weet je zeker dat je wil uitloggen?',
        //Game select
        'facilitator.gameselect.title': 'Kies een spel',

        //Grouping
        'facilitator.groupsize.title': 'Hoeveel teams?',
        'facilitator.groupsize.instruction': 'De aanbevolen teamgrootte is 3-4 spelers. Het maximumaantal teams is 6',
        'facilitator.groupmoderation.title': 'Spelleiding',
        'facilitator.groupmoderation.instruction': 'Teams zijn klaar',
        'facilitator.groupmoderation.namereset': 'Andere naam kiezen',
        'facilitator.groupmoderation.info': 'De spelers zijn aan het inloggen op het spel. Start het spel wanneer iedereen ingelogd is.',

        //Facilitator wait screen
        'facilitator.waitscreen.read': 'De spelers zijn bezig met lezen. Je kunt de tekst ook voorlezen.',
        'facilitator.waitscreen.discuss': 'De spelers zijn een vraag aan het bespreken.',
		'facilitator.waitscreen.multiquestion.discuss': 'De spelers zijn aan het discussiëren. Als je verder gaat, krijgen ze de antwoordopties en de tips.',
		'facilitator.waitscreen.openquestion.discuss': 'De spelers zijn aan het discussiëren. Als je verder gaat, krijgen ze het antwoordveld en de tips.',
        'facilitator.waitscreen.debatewheel': 'Er wordt een willekeurige speler geselecteerd om het antwoord uit te leggen.',
        'facilitator.waitscreen.voteresults': 'De spelers bekijken de uitslag van de stemming.',
        'facilitator.waitscreen.debateresults': 'De spelers bekijken de resultaten.',

        //Big screen setup
        'facilitator.setupbigscreen.title': 'Gedeeld scherm',
        'facilitator.setupbigscreen.subtitle': 'Open de spelweergave door te navigeren naar:',
        'facilitator.setupbigscreen.description': 'pulmikas.net/nl/screen',
        'facilitator.setupbigscreen.screencode': 'Spel-ID',
        'facilitator.setupbigscreen.instructiontitle': 'Hulp:',
        'facilitator.setupbigscreen.instructioncontent-1': 'Het gedeelde spelscherm moet zichtbaar zijn voor alle spelers.',
        'facilitator.setupbigscreen.instructioncontent-2': 'Om het scherm van dit toestel te delen, open je een ander browservenster en ga je naar pulmikas.net/nl/screen.',
        'facilitator.setupbigscreen.instructioncontent-3': 'Gebruik je een ander toestel voor het delen van je scherm, ga dan op dat toestel naar pulmikas.net/nl/screen en voer de ID in in het venster dat wordt geopend.',
        'facilitator.setupbigscreen.url': "/nl/screen",
        //Fact question
        'facilitator.factquestion.answermoderation.answers': 'antwoorden',

        //Multi question
        'facilitator.multiquestion.answerreset': 'Antwoord verwijderen',
        'facilitator.multiquestion.answer': 'Antwoord:',
        'facilitator.multiquestion.answerscreen.waitingforanswer': 'Aan het wachten op antwoord',
        'facilitator.multiquestion.answerscreen.ready': 'Klaar',
        'facilitator.multiquestion.debatemoderation.title': 'Uitleg',
        'facilitator.multiquestion.debatemoderation.speaking': 'Team dat momenteel aan het woord is:',
        'facilitator.multiquestion.debatemoderation.accepted': 'Aanvaard',
        'facilitator.multiquestion.debatemoderation.declined': 'Afgewezen',
        'facilitator.multiquestion.debatemoderation.instruction': 'Luister naar de uitleg van het team',
        'facilitator.multiquestion.answer.answer': 'Wacht terwijl de spelers de tips bespreken en hun antwoord kiezen.',
        'facilitator.multiquestion.answer.result': 'De spelers bekijken de resultaten.',
        //FACILITATOR VIEW MESSAGES END

        //SCREEN VIEW MESSAGES START

        //Start
        'screen.welcomescreen.title': 'Welkom',
        'screen.welcomescreen.instruction': 'Ga naar pulmikas.net',

        //Fact question
        'screen.factquestion.answerScreen.title': 'Juist antwoord',

        //Multiple choice question
        'screen.multiquestion.debatescreen.instruction': 'Leg uit waarom de optie die je gekozen hebt volgens jou de beste is',

        //Open question
        'screen.openquestion.debatescreen.instruction': 'Leg het antwoord van je team uit',
        'facilitator.openquestion.vote': 'De spelers stemmen op het beste antwoord.',


        //Debate views
        'screen.debatewheel.title': 'Wie gaat het uitleggen?',
        //SCREEN VIEW MESSAGES END

        //Universal
        'thanksforplaying': "Bedankt om mee te spelen",
        'startbutton': 'Kies een spel',
        'startgamebutton': 'Een spel starten',
        'startnewgame': 'Een nieuw spel starten',
        'logout': 'Uitloggen',
        'readybutton': 'Klaar',
        'nextbutton': 'Volgende',
        'group': 'Team',
        'wait': 'Wachten',
        'yes': 'Ja',
        'no': 'Nee',
        'hint': 'Tip',
        'discuss': 'Bespreken',
        'listen': 'Luisteren',
        'debate': 'Uitleggen',
        'forward': 'Verdergaan',
        'send': 'Versturen',
        'press1second': 'Twee seconden vasthouden',
        'waitMoment': 'Even wachten',
        'errorMessage': 'Er is iets misgegaan, probeer opnieuw in te loggen'
    }
  };